import React, {useEffect, useState} from "react"

import Navbar from "../../../../../components/navbar"
import Layout from "../../../../../components/layout"

import * as Services from "../../../../../services"

import {Bar} from "react-chartjs-2"
import {Chart as ChartJS, registerables} from "chart.js"
import {useFormik} from "formik";
import {urlPub} from "../../../../../config";


ChartJS.register(...registerables)

function DownloadPage() {
    const [day, setDay] = useState("")
    const [farmDet, setFarmDetail] = useState(null);
    const [calculos, setCalculos] = useState();
    const [evaluaciones, setEvaluaciones] = useState([]);
    const [headers, setHeaders] = useState({});
    const [chartData, setChartData] = useData(calculos);
    const [hasResta, setHasResta] = useState(false);
    const [id, setID] = useState(0);
    const [interpretacion, setInterpretacion] = useState({})
    const [cultivo, setCultivo] = useState('');

    const formik = useFormik({
        initialValues: {
            title: '',
            body: '',
            grafica: '',
            evaluacionID: id,
            dia: day,
        },
        onSubmit: values => {
            // console.log(interpretacion);
            if (interpretacion?.id === undefined) {
                const interpretation2 = {
                    ...values,
                    dia: day,
                };
                Services.AddInterpretation(interpretation2)
                    .then(response => {
                        if (response) {
                            downloadPDF()
                        }
                    }, (error) => {
                        console.log(error);
                    })
            } else {
                const interpretacion2 = {
                    ...interpretacion,
                    ...values,
                    dia: day,
                }

                Services.updateInterpretation(interpretacion2)
                    .then(response => {
                        if (response) {
                            downloadPDF()
                        }
                    }, (error) => {
                        console.log(error);
                        downloadPDF();
                    });
            }
        },
    });

    const downloadPDF = () => {
        Services.GeneratePDF(id, day).then(response2 => {
            let alink = document.createElement('a');
            alink.href = `${urlPub}${response2.url}`;
            alink.target = 'blank'
            alink.click();
        }, error => {

        })
    }

    useEffect(() => {
        const dia = localStorage.getItem("dia")
        const farm = localStorage.getItem("productor")
            ? JSON.parse(localStorage.getItem("productor"))
            : null;
        const reporte = localStorage.getItem("reporte")
            ? JSON.parse(localStorage.getItem("reporte"))
            : null;
        setFarmDetail(farm)
        setDay(dia)
        Services.ReportsDetail(reporte?.id, dia)
            .then(response => {
                console.log(response.reportes.id)
                setHeaders({objetivo: response.objetivo});
                setEvaluaciones(response.reportes.evaluaciones);
                setCalculos(response.calculos);
                setID(response.reportes.id);
                formik.setValues({
                    evaluacionID: response.reportes.id,
                    dia: day,
                });
                setCultivo(response.reportes.cultivo);

                // console.log(response.interpretaciones.length);

                if (response.interpretaciones.length >= 1) {
                    const {interpretaciones} = response;
                    const interpretation2 = interpretaciones[0];
                    console.log(interpretation2);
                    setInterpretacion(interpretation2);
                    formik.setValues({
                        title: interpretation2.title,
                        body: interpretation2.body,
                        grafica: interpretation2.grafica,
                        evaluacionID: interpretation2.evaluacionID,
                        dia: interpretation2.dia,
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    return (
        <Layout>
            <Navbar/>
            <div className="titleGeneral h-1/4 min-h-1/4">
                <div className="font-bold text-white text-center pt-8 pb-12">
                    Reporte del día: <br/> {day}
                </div>
            </div>

            <div className="bg-white pt-6 px-6 overflow-y-auto h-3/4 min-h-3/4 rounded-t-3xl -mt-8 ">
                <div className="font-bold text-black pt-8 pb-12">
                    <div>
                        Productor: {farmDet?.name}
                    </div>
                    <div>Razón Social: {farmDet?.razonSocial}</div>
                    <hr/>
                    {headers?.objetivo && (
                        <div>
                            Objetivo: {headers?.objetivo}
                        </div>
                    )}

                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-2">
                        <label htmlFor="title">Titulo del reporte (Se mostrara en el PDF)</label>
                        <input type="text" name="title" className="input" id="title" onChange={formik.handleChange}
                            value={formik.values.title}/>
                    </div>

                    <div className="mb-1">
                        <label htmlFor="body">Descripción del reporte (Se mostrara en el PDF)</label>
                        <textarea className="w-full input" name="body" onChange={formik.handleChange}
                            value={formik.values.body}></textarea>
                    </div>
                    <br/>

                    {evaluaciones?.map((testigo, index) => {
                        return (
                            <div key={index}>
                                <div className="titleGeneral h-1/4 min-h-1/4">
                                    <div className="font-bold text-white text-center py-2 pl-3 pr-10 ">
                                        {testigo.dia}
                                    </div>
                                </div>

                                <div className="relative mt-1 mb-2 rounded-md shadow-md w-full ">
                                    <label
                                        className="block w-full text-black text-center green-bg border-none py-2 pl-3 pr-10 font-bold text-white focus:ring-0">
                                        {testigo.tipo.toUpperCase()}
                                    </label>
                                </div>
                                { cultivo === 'Fresa' && (
                                    <table className="w-full">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>No. Hojas</th>
                                        <th>No. Coronas</th>
                                        <th>No. Frutos</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {testigo.datosRegistrados?.map(function (dato, id) {
                                        return (
                                            <tr key={id}>
                                                <td>T{parseInt(id) + 1}</td>
                                                <td>
                                                    <div className="relative mt-1 rounded-md shadow-md w-full">
                                                        <p className="text-center">{dato.hojas}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="relative mt-1 rounded-md shadow-md w-full">
                                                        <p className="text-center">{dato.coronas}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="relative mt-1 rounded-md shadow-md w-full">
                                                        <p className="text-center">{dato.frutos}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td>Prom</td>
                                        <td>
                                            <div className="relative mt-1 rounded-md shadow-md w-full">
                                                <p className="text-center">{calculos?.hojas[index]}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="relative mt-1 rounded-md shadow-md w-full">
                                                <p className="text-center">{calculos?.coronas[index]}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="relative mt-1 rounded-md shadow-md w-full">
                                                <p className="text-center">{calculos?.frutos[index]}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                    </table>
                                )}

                                { cultivo !== 'Fresa' && (
                                    <table className="w-full">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>B. Basales</th>
                                        <th>B. Ciegos</th>
                                        <th>B. Abiertos</th>
                                        <th>Frutos</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {testigo.datosRegistrados?.map(function (dato, id) {
                                        return (
                                            <tr key={id}>
                                                <td>T{parseInt(id) + 1}</td>
                                                <td>
                                                    <div className="relative mt-1 rounded-md shadow-md w-full">
                                                        <p className="text-center">{dato.bBasales}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="relative mt-1 rounded-md shadow-md w-full">
                                                        <p className="text-center">{dato.bCiegos}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="relative mt-1 rounded-md shadow-md w-full">
                                                        <p className="text-center">{dato.bAbiertos}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="relative mt-1 rounded-md shadow-md w-full">
                                                        <p className="text-center">{dato.frutos}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td>Prom</td>
                                        <td>
                                            <div className="relative mt-1 rounded-md shadow-md w-full">
                                                <p className="text-center">{(testigo.datosRegistrados?.reduce((total, item) => total + parseInt(item.bBasales, 10), 0)/5).toFixed(2)}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="relative mt-1 rounded-md shadow-md w-full">
                                                <p className="text-center">{(testigo.datosRegistrados?.reduce((total, item) => total + parseInt(item.bCiegos, 10), 0)/5).toFixed(2)}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="relative mt-1 rounded-md shadow-md w-full">
                                                <p className="text-center">{(testigo.datosRegistrados?.reduce((total, item) => total + parseInt(item.bAbiertos, 10), 0)/5).toFixed(2)}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="relative mt-1 rounded-md shadow-md w-full">
                                                <p className="text-center">{(testigo.datosRegistrados?.reduce((total, item) => total + parseInt(item.frutos, 10), 0)/5).toFixed(2)}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                    </table>
                                )}

                            </div>
                        )
                    })}
                    <br/>

                    <div className="mb-1">
                        <label htmlFor="grafica">Texto de interpretación de la gráfica (Se mostrara en el
                            PDF)</label>
                        <textarea className="w-full input" name="grafica" onChange={formik.handleChange}
                            value={formik.values.grafica}></textarea>
                    </div>
                    <div className="relative mt-1 mb-2 rounded-md shadow-md w-full ">
                        <label
                            className="block w-full text-black text-center green-bg border-none py-2 pl-3 pr-10 font-bold text-white focus:ring-0">
                            Gráfica comparativa
                        </label>
                    </div>

                    <Bar
                        data={chartData}
                        options={{
                            responsive: true,
                            title: {text: "THICCNESS SCALE", display: true},
                            scales: {
                                yAxes: {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            },
                        }}
                    />

                    <button
                        className="inline-flex items-center justify-center p-3 w-full bg-green-light-gu rounded-full font-bold text-white focus:ring-2 focus:ring-green-light-gu focus:bg-green-gu"
                        type="submit"
                    >
                        Descargar Reporte
                    </button>
                </form>
            </div>
        </Layout>
    )
}

export default DownloadPage

const useData = (data = {}) => {
    let valores = []
    let valores1 = []
    let labels = []

    Object.keys(data).map((e, i) => {
        labels.push(e)
        valores.push(data[e][0])
        valores1.push(data[e][1])
    })

    data = {
        labels: labels ? labels : "[1,2,3]",
        datasets: [
            {
                label: "Testigo",
                data: valores ? valores : "[0]",
                backgroundColor: "#6D993E",
                borderColor: "#6D993E",
                borderWidth: 1,
            },
            {
                label: "Tratamiento",
                data: valores1 ? valores1 : "[0]",
                backgroundColor: "#8bfd78",
                borderColor: "#8bfd78",
                borderWidth: 1,
            },
        ],
    }
    return [data]
}
